@import '~theme/variables';

.section {
  position: relative;
}

.container {
  padding-left: $space-xs;
  padding-right: $space-xs;

  @include breakpoint(sm) {
    padding-left: $space-xl;
    padding-right: $space-xl;
  }

  @include breakpoint(md) {
    padding-left: 0;
    padding-right: 0;
  }
}

.description {
  margin-bottom: $space-l;
  text-align: left;

  @include breakpoint(sm) {
    margin-bottom: $space-xl;
    text-align: center;
  }
}

.subtitle {
  margin: 3.2rem 0 1.6rem;

  @include breakpoint(md) {
    margin: 2.4rem 0 3.2rem;
  }

  @include breakpoint(lg) {
    margin: 2.4rem 0 1.6rem;
  }
}

.clubTitleContainer {
  text-align: center;
  margin-bottom: $space-l;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  z-index: 0;

  img {
    max-width: 15.7rem;
  }
  @include breakpoint(md) {
    margin-bottom: $space-xl;
  }
}

.moreInfoContainer {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  gap: $space-s;

  @include breakpoint(md) {
    flex-direction: row;
    align-items: center;
  }

  &.center {
    justify-content: center;
  }
}

.rating {
  color: $color-neutral-01;

  flex-direction: column;

  @include breakpoint(md) {
    flex-direction: row;
    align-items: center;

    & span,
    & button {
      font-size: $large-font-size;
    }
  }
}

.moreInfoButton span {
  font-weight: 400;

  @include breakpoint(md) {
    font-size: $large-font-size;
  }
}

.cardWrapper {
  row-gap: $space-l;
  margin-bottom: $space-m;
  margin-top: $space-m;

  @include breakpoint(md, max) {
    row-gap: $space-xxl;
  }
}

.cardLink {
  height: 100%;
}

.image {
  display: none;
  @include breakpoint(sm) {
    display: block;
  }
}

.curlyLine {
  position: unset;

  svg {
    top: 50%;
    z-index: 0;
  }
}
